import styled from "@emotion/styled"

export const MainHeader = styled.header`
  z-index: 1000;
  box-shadow: 0 2px 10px 0 rgb(36 50 66 / 8%);
  position: fixed !important;
  width: 100%;
  background: #fff;
  min-height: 60px;
`
export const MainHeaderWrapper = styled.div`
  position: relative;
`

export const MainHeaderMobile = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  // min-height: 50px;

  @media (max-width: 600px) {
  }

  @media (min-width: 600px) {
    display: none;
  }
`

export const HeaderMobileNav = styled.div`
  min-height: 1em;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 1em 10px;
`

export const HeaderDesktopNav = styled.div`
  display: flex;
  flex-direction: row;

  & ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;

    & li {
      display: flex;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      position: relative;

      & .tags-list {
        padding-top: 0;
        padding-bottom: 0;
        text-decoration: none;
        padding: 1em 1em;
        display: flex;
        transition: all 0.2s linear;
        font-size: 18px;

        cursor: pointer;

        transition: none;

        @media (max-width: 600px) {
          padding-top: 0.8em;
        }
      }

      & a {
        padding-top: 0;
        padding-bottom: 0;
        text-decoration: none;
        padding: 1em 1em;
        display: inline-block;
        transition: all 0.2s linear;
        font-size: 18px;

        transition: none;

        @media (max-width: 600px) {
          padding-top: 0.8em;
        }
      }
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`

export const MainHeaderDesktop = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;

  @media (min-width: 600px) {
    justify-content: space-between;
  }

  @media (max-width: 600px) {
    height: 50px;
    justify-content: center;
  }
`

export const HeaderNav = styled.nav``

export const HeaderMenuButton = styled.div`
  margin-top: 0px;
  pointer-events: all;
  cursor: pointer;
  max-width: 140px;
`

export const HeaderMenuButtonContent = styled.div`
  position: relative;
  // width: 40px;
  // height: 40px;
`

export const HeaderMenuButtonHum = styled.ul`
  display: flex;
  margin: 12px 14px;
  pointer-events: none;
  & li {
    width: 2px;
    height: 1em;
    background: black;
    z-index: 0;
    transform-origin: center center 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    &:not(:last-child) {
      margin-right: 3px;
    }
  }
`

export const HeaderMenuButtonClose = styled.ul`
  display: flex;
  margin: 12px 14px;
  pointer-events: none;
  & li {
    width: 2px;
    height: 1em;
    background: black;
    z-index: 0;
    transform-origin: center center 0px;
    transform: matrix(0.7071, -0.7071, 0.7071, 0.7071, 5, 0);
    &:not(:last-child) {
      margin-right: 3px;
    }
  }
`

export const HeaderMenuButtonText = styled.p`
  font-size: 1em;
  letter-spacing: 0.5px;
  position: absolute;
  // top: 13px;
  top: 10px;
  // left: 52px;
  left: 45px;
`

export const HeaderSearch = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 8px;
`

export const HeaderNavButton = styled.div`
  display: inline-block;
  margin: 0 8px;
  pointer-events: all;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`

export const HeaderNavButtonContainer = styled.div`
  position: relative !important;
  width: 40px;
  height: 40px;
`

export const HeaderNavButtonIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`

export const Circle = styled.div`
  position: absolute !important;
  left: 50%;
  top: 50%;
  width: 80px;
  height: 80px;

  transform: translate(-50%, -50%);
  pointer-events: none;
`

export const Canvas = styled.canvas`
  z-index: 0;
  top: 0px;
  left: 0px;
  position: absolute !important;
  transform: matrix(0, -1, 1, 0, 0, 0);
`

export const MobileNavigationLabel = styled.div`
  font-size: 28px;
  width: 100%;
  padding: 10px 0px;
`
export const MobileNavigationTagOpener = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`

export const MobileNavigationTagList = styled.ul`
  & li {
    background-color: #fce8e6;
    border-top: 3px solid #e8eaed;

    &:first-child {
      border-top: 3px solid black;
    }
    & a {
      padding: 10px 20px;
      font-size: 0.8em;
    }
  }
`

export const MobileHeaderSearchInput = styled.input`
  position: absolute;
  top: 0;
  right: 20px;
  width: 290px;
  background: #fff;
  border: 1px solid;
  outline: 0;
  font-family: gotham-bold, sans-serif;
  font-size: 13px;
  line-height: 38px;
  text-align: left;
  padding: 0 0.75em;
  border-radius: 15px;
  margin-right: -30px;
`
// export const MainHeaderComponents = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   position: relative;
//   @media (max-width: 600px) {
//     flex-direction: column;
//     just-content: center;
//     align-items: center;
//   }
// `

// export const MainHeaderNavContainer = styled.div`
//   margin-left: auto;
//   height: 100%;
//   text-align: right;
//   @media (max-width: 600px) {
//     margin-left: 0px;
//   }
// `

// export const MainHeaderNav = styled.nav`
//   flex-grow: 1;
//   height: 100%;
//   & div {
//     padding-left: 15px;
//     vertical-align: top;
//     height: 100%;
//     display: inline-flex;
//   }
// `
// export const MainHeaderNavList = styled.ul`
// list-style: none;
// margin: 0;
// padding-left: 0;
// position: relative;
// display: flex;
// flex-wrap: wrap;
// justify-content: flex-end;
// `
// export const MainHeaderNavListItem = styled.li`

//   & .menu-link {

//   }
//   &:hover > ul {
//     opacity: 1;
//     visibility: visible;
//     transition: opacity ease-in-out 0.3s;
//   }
// `

// export const MainHeaderDropDownArrow = styled.span`
//   transition: none;
//   &:after {
//     content: "\\e900";
//     display: inline-block;
//     font-size: 0.6rem;
//     font-weight: bold;
//     text-rendering: auto;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     margin-left: 10px;
//     line-height: normal;
//   }
// `

// export const MainHeaderSubMenu = styled.ul`
//   opacity: 0;
//   visibility: hidden;
//   transition: opacity ease-in-out 0.3s;
//   border-top-width: 2px;
//   border-right-width: 0px;
//   border-left-width: 0px;
//   border-bottom-width: 0px;
//   border-style: solid;
//   box-shadow: 0 4px 10px -2px rgb(0 0 0 / 10%);
//   width: 240px;
//   background: #fff;
//   left: -999em;
//   position: absolute;
//   top: 100%;
//   z-index: 99999;
//   border-color: #173e43;
//   right: auto;
//   left: 0;
//   list-style: none;
//   margin: 0;
//   padding-left: 0;
//   text-align: left;
// `
